import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProductsPage = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await axios.get('https://0pvpvo6yg1.execute-api.ap-southeast-2.amazonaws.com/prod');
            setProducts(response.data);
        };

        fetchProducts();
    }, []);

    return (
        <div>
            <h1>Products</h1>
            {products.map(product => (
                <div key={product.productId}>
                    <h2>{product.title}</h2>
                    <p>{product.description}</p>
                    <img src={product.imageURL} alt={product.title} />
                    <a href={product.affiliateLink}>Buy on Amazon</a>
                </div>
            ))}
        </div>
    );
};

export default ProductsPage;
